<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">CRUISE TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'CR-ST'" :coverType="'CR'"/>

				<div class="content">
					<h2>Do I need cruise travel insurance?</h2>
					<p class="mb-5">
						Cruise travel insurance has been designed specifically to cover you for unexpected things which may happen on a cruise, for example, transport to hospital if you are taken ill or if you missed the departure time at one of your ports on your itinerary. 
						<br><br>
						Embarking on a cruise is a great way to travel, as you get to experience several different places on one holiday. Being onboard makes it especially important to take out cruise-specific travel insurance so you can enjoy exploring the seas, with the added peace of mind knowing that you are covered for any unexpected events.
						<br><br>
						Our Frequently Asked Questions should help provide you with the information you need when considering a cruise travel insurance policy.
					</p>

					<h2>What does cruise travel insurance cover?</h2>
					<p class="mb-5">
						The main benefit of getting cruise travel insurance, is the cruise-specific benefits it provides. 
						<br>
						Our policies will cover you if:
					</p>
					<p class="mb-5">
						<ul>
							<li>You need emergency medical assistance, including helicopter transfers, hospital and ambulance fees and cover to help you get home, if you are unable to use your original inbound ticket.</li>
							<li>You skip a port. This cruise-specific benefit will cover you if a planned destination on your itinerary is cancelled due to adverse weather or timetabling. For example, if you miss an island or city you were looking forward to visiting on your cruise, you may be able to claim some money back. </li>
							<li>You miss a port. We will cover you if you miss your departure, due to your transportation for example, your car is undriveable (due to an accident or mechanical failure), we will pay for additional travel expenses to reach your next overseas destination. This benefit also applies if you travel using public transport and there are unavoidable delays. </li>
							<li>You are confined to your cabin due to illness. </li>
							<li>You do not take part in your pre-booked excursions due to accident, injury, or illness. </li>
							<li>Your baggage is lost, stolen, or damaged.</li>
							<li>You need to cancel due to accident, illness, injury or redundancy. </li>
							<li>You need to come home early, due to accident, illness or injury, as well as provide you with a pro-rata refund for pre-paid, unused trip costs. </li>
						</ul>
					</p>

					<p class="mb-5">
						There are terms, conditions and exclusions on our Cruise policy, please take the time to read our <a href="/policy-wordings-mul">Policy Wording</a> which will provide you with the full details of cover.
					</p>

					<h2>Do I still need cruise travel insurance if I have an EHIC?</h2>
					<p class="mb-5">
						<strong>An EHIC or GHIC is not a replacement for cruise travel insurance</strong>. Although both
						the EHIC and GHIC will entitle you to receive emergency medical treatment abroad, they will not
						cover for repatriation if you need to return to the UK (medical repatriation) – something which
						is included as standard in many travel insurance policies.
						<br /><br />
						EHIC’s and GHIC’s will not cover you on the cruise ship but it will on dry land – even if you
						are just sailing around the Mediterranean. We offer travel insurance cover for cruises, which
						will not only cover your medical expenses, but also include cabin confinement, cover for your
						formal attire, missed port stops and missed excursions.
					</p>

					<h2>Will the destinations on my itinerary have an impact on the cost of my travel insurance?</h2>
					<p class="mb-5">
						Depending on where you are travelling to, your destinations may well have an impact on your travel insurance premium. 
						<br>
						For example, the following countries all have a high proportion of private health care compared to state provided health care. Therefore, the cost of travel insurance may well increase if you are travelling to:
					</p>
					<p class="mb-5">
						<ul>
							<li>North and Central America</li>
							<li>China and Hong Kong</li>
						</ul>
					</p>
					<p class="mb-5">
						Due to this, the costs of travel insurance may be more if you are travelling further afield. To give you an idea of the costs of medical care and repatriation, a stomach bug or infection treated in the USA, with return flights have seen costs in the region of £100,000. 
					</p>

					<h2>What if I have pre-existing medical conditions?</h2>
					<p class="mb-5">
						It is important to declare any pre-existing medical conditions to our medical screening team to ensure you have full cover for your cruise. If you do not disclose your conditions, and you have a medical emergency abroad, which is linked to your condition, then the claim could be refused. Any conditions, such as, high blood pressure, or diabetes, are best to disclose, as you could be risking thousands if you choose not to. 
						<br><br>
						For more information on cover for pre-existing medical conditions, <a href="https://www.covered2go.co.uk/pre-existing-medical-conditions">click here</a>.
					</p>

					<h2>Our top tips when choosing cruise travel insurance</h2>
					<p class="mb-5">
						<ul>
							<li>Ensure you choose a policy with the right cancellation amount i.e. covers the cost of your trip, so that you can get a refund should you need to cancel. </li>
							<li>Choose the right cover limits for you, for example, if you are travelling to Europe, the minimum amount of medical cover recommended is £1 Million, and £2 Million for the rest of the world. Our cruise policy’s medical expenses limit is £10,000,000. </li>
							<li>Cover for medical conditions is really important. We consider all medical conditions so ensure you disclose these to ensure you are fully covered.</li>
						</ul>
					</p>

					
				</div>
				
				<InfoButtonOptions :policyType="'CR-ST'" :coverType="'CR'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'CruiseTravelInsurance',
	data() {
		return {

		}
	},
	methods: {

	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
</style>
